/* body {
    background-color: #272d4b;
    /* align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
    flex-direction: column; 
        } */
.logoGrupa {
    width: 25rem;
    margin-top: 15rem !important;
    margin-bottom: 2rem;
       
    }
.buttonZaloguj {
    display: flex !important;
    padding: 10px 25px 10px 25px !important;
    margin: 10px auto !important;
    }
    
 .inputEmail {
    margin: 10px auto !important;
    display: flex;
    align-content: center;
    justify-content: center;
    width: 15rem;
    }

.cardLogin{
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
    flex-direction: column;
    }


